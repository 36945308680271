import type { ComponentType } from "react"
import { motion } from "framer-motion"
import * as React from "react"

// Created using FramerOverrides.com - do not distribute

export const withTap = (Component: ComponentType): ComponentType => {
    return (props) => (
        <Component
            {...props}
            as={motion.div}
            style={{}}
            whileTap={{
                scale: 0.9,
                y: 3,
                transition: {
                    duration: 0.3,
                    ease: "anticipate",
                    repeat: 0,
                    repeatDelay: 0,
                    delay: 0,
                },
            }}
        />
    )
}
export const withScale = (Component: ComponentType): ComponentType => {
    return (props) => (
        <Component
            {...props}
            as={motion.div}
            style={{}}
            whileHover={{
                scale: 1.3,
                transition: {
                    duration: 0.15,
                    ease: "spring",
                    repeat: 0,
                    repeatDelay: 0,
                    delay: 0,
                },
            }}
        />
    )
}
export function withGreeting(Component): ComponentType {
    return (props) => {
        const hours = new Date().getHours()
        let greeting

        if (hours < 12) {
            greeting = "Good morning,"
        } else if (hours >= 12 && hours <= 17) {
            greeting = "Good afternoon,"
        } else {
            greeting = "Good evening,"
        }

        return <Component {...props} text={greeting} />
    }
}
